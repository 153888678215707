<template>
  <div class="col-lg-6 my-2">
    <div class="row justify-content-center">
      <div class="col-auto text-center">
        <div class="page_title">
          DONATE
        </div>
      </div>
      <div class="col-12">
        <div class="page_title_underline mt-3 mx-auto"></div>
      </div>
    </div>
    <div class="row mx-0 my-4 my-md-5 justify-content-center">
      <div class="col-md-10 p-0" >
        <ProjectDonate v-if="canDonate" :project="project" :mergedProject="mergedProject" :currency="currency" />
        <div v-else >
          This project is currently not available for donation.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    ProjectDonate: defineAsyncComponent(() => import('./ProjectDonate.vue'))
  },
  name: 'Project Donate Section',
  props: ['project', 'mergedProject', 'currency'],
  computed: {
    canDonate () {
      if (this.project && this.project.adminProjectId) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
  .page_title {
    font-size: 14px;
    color: var(--green-color);
    font-family: "quicksand_bold", Sans-serif;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 6.8px;
  }
  .page_title_underline {
    border-bottom: 2px solid var(--gold-color);
    width: 131px;
  }
  @media (min-width: 992px) {
    .page_title {
      font-size: 21px;
    }
    .page_title_underline {
      width: 200px;
    }
  }
</style>
